import { io } from 'socket.io-client';
import { debounce } from '../utils/debounce';

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.NODE_ENV === 'production' ? 'https://api-smile.dev.vinivia.live' : 'http://localhost:3002';

export const socket = io(URL,
  {
    autoConnect: false,
    transports: ["websocket", "polling"] // use WebSocket first, if available
  }
);

function sendEmoji(id: number) {
  socket.emit('message', { smileId: id });
}

export const debouncedSendEmoji = debounce(sendEmoji, 100);

import React, { ImgHTMLAttributes } from "react";
import LolImage from "./png/lol.png";
import LoveImage from "./png/love.png";
import NiceImage from "./png/nice.png";
import OkImage from "./png/ok.png";
import OmgImage from "./png/omg.png";
import XoxoImage from "./png/xoxo.png";
import YasImage from "./png/yas.png";

type EmojiReactionProps = {
  name: string;
} & ImgHTMLAttributes<{}>;

export const emojiReactionsMap = {
  Lol: LolImage,
  Love: LoveImage,
  Nice: NiceImage,
  // Ok: OkImage,
  Omg: OmgImage,
  Xoxo: XoxoImage,
  // Yas: YasImage,
};


export const EmojiReaction: React.FC<EmojiReactionProps> = (props) => {
  // @ts-ignore
  const srcImage = emojiReactionsMap[props.name];

  return (
    <img
      style={{ cursor: "pointer", zIndex: 104, pointerEvents: "auto" }}
      src={srcImage}
      alt="Emoji"
      {...props}
    />
  );
};

import React from 'react';
import styled from 'styled-components';
import Logo from './vinivia-logo.png';
import Vinivia from './vinivia2.png';

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #f55781;
  z-index: 1001;
`;

const LogoWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const StyledLogo = styled.img`
  position: absolute;
  bottom: 50%;
  width: 100px;
  height: 110px;
`;

const StyledVinivia = styled.img`
  position: absolute;
  bottom: 10%;
  width: 95px;
  height: 24px;
`;

const StyledText = styled.div`
  position: absolute;
  bottom: 30%;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 48px;
  color: #ffffff;
`;

export const ViniviaOverlay = () => {
  return (
    <Wrapper>
      <LogoWrapper>
        <StyledLogo src={Logo} />
        <StyledText>starting the live</StyledText>
        <StyledVinivia src={Vinivia} />
      </LogoWrapper>
    </Wrapper>
  );
};

import styled from 'styled-components';

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  input,
  button {
    border-radius: 10px;
    padding: 10px;
  }
`;

export const Input = styled.input`
  width: 50%;
  margin-bottom: 10px;
`;

export const Checkboxes = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  > div {
    color: white;
    font-weight: bold;
    padding: 5px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  background-color: #f55781;
  color: white;
  border-radius: 10px;
  font-weight: bold;
  margin: 0 10px 10px 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

import { useEffect, useState, useRef } from "react";
// import "./styles.css";
import { EmojiReaction, emojiReactionsMap } from './emoji/EmojiReaction';
import './index.css';
import styled from 'styled-components';
import { debouncedSendEmoji, socket } from "./socket";

type LikesBag = Array<{
  id: number // timestamp
  emoji: string // Emoji identifier
}>

export const Animation = () => {
  const [likes, setLikes] = useState<LikesBag>([]);
  // Clean like is a function that accepts an id to clear a specific like from the like bag
  const cleanLike = useRef((id: number) => {
    setLikes((currentLikes) => currentLikes.filter((like) => like.id !== id));
  });
  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }
    const onNewEmoji = (payload: any) => {
      console.log('onNewEmoji', payload)
      try {
        const { smileId } = payload
        const id = new Date().getTime();
        const name = Object.entries(emojiReactionsMap)[smileId][0]
        setLikes((prevLikes) => [
          ...prevLikes,
          {
            id: id,
            emoji: name
          }
        ])
      } catch (e) {
        console.log(e)
        return
      }

    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('message', onNewEmoji);
    socket.connect();

    return () => {
      socket.disconnect()
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('message', onNewEmoji);
    };
  }, []);

  return (
    <GlassOuterContainer>
      <GlassContainer>
        <div
          className="glassContainer"
        >
          {Object.entries(emojiReactionsMap).map((entry, index) => {
            const name = entry[0];
            return (
              // @ts-ignore
              <EmojiReaction

                key={name}
                width="100%"
                name={name}
                onClick={() => {

                  const id = new Date().getTime();
                  if (isConnected) {
                    debouncedSendEmoji(index)
                  }
                  setLikes((prevLikes) => [
                    ...prevLikes,
                    {
                      id: id,
                      emoji: name
                    }
                  ])
                }}
              />
            )
          })}
        </div>
      </GlassContainer>
      {likes.map((like) => (
        <Bubble onAnimationEnd={cleanLike.current} key={like.id} like={like} />
      ))}
    </GlassOuterContainer>
  );
}

function random(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

const opacityDuration = 1;


// @ts-ignore
function Bubble({ like, onAnimationEnd }) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [opacity, setOpacity] = useState(1);
  const size = useRef(random(0.6, 0.9));

  const element = useRef();

  const initialOptions = useRef({
    animationDuration: random(0.2, 1.3),
    element,
    onAnimationEnd,
    id: like.id
  });

  useEffect(() => {
    const {
      animationDuration,
      element,
      onAnimationEnd,
      id
    } = initialOptions.current;

    // @ts-ignore
    element.current.addEventListener("transitionend", (event) => {
      if (event.propertyName === "opacity") {
        onAnimationEnd(id);
      }
    });

    setTimeout(() => {
      setPosition((prevState) => ({
        ...prevState,
        x: random(-120, 0),
        y: random(-100, -200)
      }));
    }, 5);

    setTimeout(() => {
      setOpacity(0);
    }, (animationDuration - opacityDuration) * 1000);
  }, []);


  return (
    <div
      style={{
        top: 0,
        zIndex: 104,
        color: "red",
        fontSize: "2em",
        left: "50%",
        opacity,
        pointerEvents: "none",
        position: "absolute",
        transform: `translate(calc(-25% + ${position.x}px), calc(40vh + ${position.y}px)) scale(${size.current})`,
        textShadow: "0 0 5px rgba(0, 0, 0, .25)",
        transition: `transform ${initialOptions.current.animationDuration}s linear, opacity ${opacityDuration}s ease-in-out`
      }}
      // @ts-ignore
      ref={element}
    >
      {/** @ts-ignore */}
      <EmojiReaction name={like.emoji} />
    </div>
  );
}

// Where animations can flow to
const GlassOuterContainer = styled.div`
  position: relative;
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overscroll-behavior: none;
  pointer-events: none;
`

// Where the glass container must be contained
const GlassContainer = styled.div`
  pointer-events: none;
  position: absolute;
  overflow-y: hidden;
  overscroll-behavior: none;
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  width: 60px;
  height: 340px;
  right: 5px;
  bottom: 10%;
  z-index: 101;

  @media (min-width: 720px) {
    width: 70px;
  }
`

import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
// @ts-ignore
import { Player, PlayerProps } from "@vinivia/player";
import styled from "styled-components";
import { Container } from "./Demo.styled";
import { ViniviaOverlay } from "./ViniviaOverlay";
import { Animation } from '../animation';

const objReducerFn = <T,>(state: T, action: Partial<T>): T => ({
  ...state,
  ...action,
});

const initialValues: PlayerProps = {
  viewUrl:
    "DIGEST:eyJhcHBsaWNhdGlvbklkIjoidmluaXZpYS5jb20iLCJkaWdlc3QiOiJRZzNvTUdqUXVlT1JiR21ZRUFOUDNHMlVrTUgzay8rNktXekZ6Y0swTitGaE9ld0NyUnVIamNFNmc3b25HVU9tWVRKWTk4R0tQTXJlMVNBQ1ZwUjRNQT09IiwidG9rZW4iOiJ7XCJleHBpcmVzXCI6MTcwOTc1MjU1NDM3OSxcInVyaVwiOlwiaHR0cHM6Ly9wY2FzdC5waGVuaXhydHMuY29tXCIsXCJyZXF1aXJlZFRhZ1wiOlwiY2hhbm5lbElkOmV1cm9wZS13ZXN0MiN2aW5pdmlhLmNvbSN0b3JzdGVuLnNFUlNvZVJWenoyYVwifSJ9",
  alias: "torsten-asks",
  disableLogging: false,
  enableBlur: false,
};

const emptyValues = {
  viewUrl: "",
  alias: "",
  disableLogging: false,
  enableBlur: false,
};

type Demo2Props = {
  viewerCount: number
}

export const Demo2 = ({viewerCount}: Demo2Props) => {
  const [isLive, setIsLive] = useState(false);

  const [draftValues, setDraftValues] = useReducer(
    objReducerFn<PlayerProps>,
    initialValues
  );

  // finalValues is not used
  const [finalValues, setFinalValues] = useReducer(
    objReducerFn<PlayerProps>,
    initialValues
  );

  // onFinish is not used
  const onFinish = useCallback(() => {
    setFinalValues(draftValues);
  }, [setFinalValues, draftValues]);

  // clearValues is not used
  const clearValues = useCallback(() => {
    setDraftValues(emptyValues);
    setFinalValues(emptyValues);
  }, [setFinalValues, setDraftValues, emptyValues]);

  useEffect(() => {
    console.log("isLive: ", isLive);
  }, [isLive]);

  const iframeUrl = useMemo(() => (
    process.env.NODE_ENV === "development" ? "http://localhost:3000/embed/chat/readwrite/" :"https://api-player.dev.vinivia.live/embed/chat/readwrite/"
  ), [])

  return (
    <>
      <Container>
        <PlayerContainer>
          <Player
            viewerCount={viewerCount || 1500}
            enableBlur={true}
            onIsLiveChanged={setIsLive}
            volumePosition="topLeft"
            volumeContainerStyle={{
              top: "70px",
              left: "20px"
            }}
            forceMobileControls={true}
            {...initialValues}
          />
          {isLive && <OverlayContainer>
            <Animation />
            <IframeContainer>
              <StyledIframe title="chat" src={`${iframeUrl}?chatWidth=82%`}/>
            </IframeContainer>
          </OverlayContainer>}
        </PlayerContainer>
      </Container>
      {!isLive && <ViniviaOverlay />}
    </>
  );
};

const PlayerContainer = styled.div`
  position: relative;
  min-width: 360px;
  height: 100%;

  @media screen and (min-width: 1024px) {
    height: auto;
  }

  width: 100%;
  display: flex;
`;
const OverlayContainer = styled.div`
  pointer-events: none;
  position: absolute;
  background-color: transparent;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const IframeContainer = styled.div`
  pointer-events: auto;
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  max-height: 30vh;
  bottom: 0;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, black 20%);
`;

const StyledIframe = styled.iframe`
  height: 100%;
  width: 100%;
  border: none;
`
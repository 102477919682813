import React, { useEffect, useState } from 'react';
// @ts-ignore
import { Player } from '@vinivia/player';
import './App.css';
import { Demo2 } from './Demo/Demo';

function App() {
  const [viewerCount, setViewerCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(async () => {
      const response = await fetch('https://api-player.dev.vinivia.live/api/status', {
        mode: 'cors',
        cache: 'no-cache',
      });
      const json = await response.json();
      setViewerCount(json.viewerCount);
    }, 5000);

    const pingInterval = setInterval(async () => {
      await fetch('https://api-player.dev.vinivia.live/api/ping', {
        mode: 'cors',
        cache: 'no-cache',
      });
    }, 20000);
    return () => {
      clearInterval(interval);
      clearInterval(pingInterval);
    }
  }, []);

  return (
    <Demo2 viewerCount={viewerCount} />
  );
}

export default App;
